.admin-app {
  .p-dropdown {
    .p-dropdown-label {
      color: $gray-800;
      font-family: 'AvenirNext LT Pro', sans-serif !important;
    }
  }

  .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: $gray-800;
    background: $gray-200;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: $green-400;
    background: $gray-200;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    font-size: 12px;
  }
}

.p-paginator .p-dropdown {
  height: auto;
}
