.react-select {
  .react-select__control {
    min-height: 30px;
    border-radius: 6px;

    &:hover {
      border-color: #6366f1;
    }

    &:focus,
    &.react-select__control--is-focused {
      outline: 0 none;
      outline-offset: 0;
      box-shadow: 0 0 0 0.2rem #c7d2fe;
      border-color: #6366f1;
    }
  }

  .react-select__single-value,
  .react-select__input {
    font-size: 12px !important;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    color: #6c757d;
    padding: 4px 12px 4px 4px;
  }

  .react-select__input-container,
  .react-select__value-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__placeholder {
    color: $gray-350;
    font-size: 12px;
  }

  .react-select__menu {
    padding: 0.75rem 0;

    .react-select__menu-list {
      padding: 0;

      .react-select__option {
        padding: 0.75rem 1.25rem;
        font-size: 12px;
      }
    }

    .react-select__option--is-focused {
      color: $gray-800;
      background: $gray-200;
    }
  }
}
