@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'primereact/resources/themes/lara-light-indigo/theme.css';
//theme
@import 'primereact/resources/primereact.min.css';
//core css
@import 'primeicons/primeicons.css';
//icons
@import 'primeflex/primeflex.css';
//primeflex

@import 'variables';
@import 'primereact/index';
@import 'form';
@import 'react-select';
@import './fonts/Avenir/stylesheet';

* {
  font-family: 'AvenirNext LT Pro', sans-serif;
}

input,
button,
a {
  font-family: 'AvenirNext LT Pro', sans-serif !important;
}

body {
  background: $gray-100;
  padding: 0;
  margin: 0;
}
