$gray-100: #f5f1ee;
$gray-150: #d6d2d3;
$gray-200: #f1ecea;
$gray-300: #d8d8d8;
$gray-350: #a19c9d;
$gray-375: #716e6f;
$gray-400: #595757;
$gray-700: #293047;
$gray-800: #333232;

$green-400: #00c6bb;
$green-500: #00b8ad;

$orange-500: #ea7100;

$red-500: #ff6c67;
