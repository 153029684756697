.p-checkbox {
  .p-checkbox-box.p-highlight {
    background: $green-400;
    border-color: $green-500;
  }
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: $green-500;
  border-color: $green-500;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $green-500;
}

.p-inputtext {
  padding: 6px 10px;
  font-size: 12px;
}

.p-radiobutton .p-radiobutton-box {
  border-width: 1px;

  &.p-highlight,
  &.p-highlight:not(.p-disabled):hover {
    border-color: $gray-100;
    background: #fff;

    .p-radiobutton-icon {
      background: $green-400;
    }
  }

  &.p-highlight:not(.p-disabled):hover {
    border-color: $green-400;
  }

  &:not(.p-disabled):not(.p-highlight):hover {
    border-color: $green-400;
  }
}

.p-inputtext:enabled:hover,
.p-inputtext:enabled:focus {
  border-color: $green-400;
}

.field-radiobutton {
  label {
    color: $gray-375;
    font-size: 12px;
  }
}
