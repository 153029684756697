.form-row {
  display: flex;
  align-items: center;

  & + .form-row {
    margin-top: 10px;
  }

  &.form-row--block {
    flex-direction: column;

    .form-row__label {
      margin-bottom: 10px;
      text-align: left;
      min-width: auto;
      color: $gray-350;
      font-weight: 500;
      width: auto;
    }
  }

  .form-row__label {
    margin-right: 20px;
    width: 150px;
    text-align: right;
    align-self: flex-start;
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    color: $gray-350;
    font-weight: 500;

    sup {
      margin-left: 10px;
    }
  }

  .form-row__field {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: end;

    .p-error.block {
      margin-top: 5px;
    }

    input,
    textarea {
      width: 100%;
      color: $gray-800;

      &::placeholder {
        color: $gray-350;
      }
    }

    &.form-row__field--auto {
      width: auto;
      align-self: start;
    }
  }
}
