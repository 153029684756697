.admin-app {
  .p-button {
    &:not(button):not(a):not(span):not(.p-disabled),
    &:not(.p-button-outlined):not(.p-button-text) {
      background: $green-400;
      border-color: $green-500;
      color: #fff;

      &:enabled {
        &:hover,
        &:focus,
        &:active {
          border-color: $green-500;
          background: $green-500;
          color: #fff;
        }
      }
    }

    &.p-button-text {
      color: $green-400;

      &:enabled {
        &:hover,
        &:focus,
        &:active {
          color: $green-500;
        }
      }

      &.p-button-danger {
        color: $red-500;

        &:enabled {
          &:hover,
          &:focus,
          &:active {
            color: $red-500;
          }
        }
      }

      &.p-button--orange {
        color: $orange-500;

        &:enabled {
          &:hover,
          &:focus,
          &:active {
            color: $orange-500;
          }
        }
      }
    }

    &:not(.p-button-outlined):not(.p-button-text).p-button-gray {
      background: $gray-100;
      color: $gray-800;
      border-color: $gray-300;
    }

    &.p-button-outlined {
      border-color: $green-500;
      color: $green-400;

      &:enabled:hover,
      &:not(button):not(a):not(.p-disabled):hover {
        border-color: $green-500;
        color: $green-400;
      }

      &.p-button--orange {
        border-color: $gray-300;
        color: $orange-500;

        &:enabled {
          &:hover,
          &:focus,
          &:active {
            color: $orange-500;
          }
        }
      }

      &.p-button--gray {
        border-color: $gray-150;
      }
    }

    &.button__back {
      color: #000;
    }

    &.p-button-sm {
      padding: 7px 10px;
      font-size: 12px;

      [class*='pi-chevron'] {
        font-size: 8px;
      }
    }
  }

  .p-button-label {
    font-weight: normal;
  }

  a.p-button {
    text-decoration: none;
  }

  span.p-button.p-button-text.p-button--orange {
    &:hover,
    &:focus,
    &:active {
      color: $orange-500;
      background: none;
    }
  }
}

.button__back {
  padding: 0;

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
