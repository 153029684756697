.p-datatable {
  .p-datatable-thead {
    & > tr {
      & > th {
        background: none;
        font-weight: 400;
        color: $gray-400;
        border: none;
        text-decoration: underline;
        font-size: 14px;

        .p-column-header-content {
          display: flex;
          justify-content: space-between;
        }

        &.p-selection-column {
          text-decoration: none;
        }
      }
    }
  }

  .p-datatable-tbody > tr.p-highlight {
    background: inherit;
    color: inherit;
    font-weight: 400;
  }

  .p-sortable-column.p-highlight,
  .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover,
  .p-sortable-column:not(.p-sortable-disabled):hover,
  .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: none;
    color: $gray-400;

    .p-sortable-column-icon {
      color: $gray-400;
    }
  }

  .p-datatable-tbody {
    & > tr {
      background: none;

      &:nth-child(odd) {
        background: $gray-200;
      }

      & > td {
        border: none;
        font-size: 14px;
        padding: 5px 10px;
      }
    }
  }

  .p-paginator {
    background: $gray-200;
    color: $gray-400;
    font-size: 14px;

    .p-paginator-current {
      color: $gray-400;
    }

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
      color: $gray-400;
    }

    .p-paginator-pages {
      .p-paginator-page {
        font-size: 14px;
      }

      .p-paginator-page.p-highlight {
        color: $gray-700;
        background: none;
        border: 1px solid $gray-300;
      }
    }

    .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-last:not(.p-disabled):not(.p-highlight):hover,
    .p-paginator-page:not(.p-highlight):hover {
      background: $gray-300;
    }
  }

  .table-actions {
    .p-button.p-button-text {
      color: $orange-500;
      text-decoration: none;
      font-size: 14px;
    }
  }
}
